// Kodet af Michelle 

import React from "react";
import {AiOutlinePlus} from 'react-icons/ai';

export default function AddTask() {

    return (
        <>
         <div className="New">
            <h2>Add new task</h2>
            <div className="plus">
                <AiOutlinePlus/>
            </div>
         </div>
        </>
       
    );
}; 