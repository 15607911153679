// Kodet af Michelle 

import React from "react";


export default function InsertText(props) {

    return (
        <div className="Insert_text">
            <p>{props.name}</p>
        </div>
    );
}; 