import { BsMoonFill } from "react-icons/bs";
//Nadia

export default function Ldcomponent() {
    return (
 <section>
 {/*light / dark mode knap/icon*/}
  <div className="ld-btn2">
  <div className="ld-container2">
    <div className="light-mode"></div>
    <div className="dark-mode"><BsMoonFill className="icon-moon"/></div>
  </div>
</div>
</section>

    )
}