// Kodet af Michelle 

import React from "react";

import {BsPerson} from 'react-icons/bs';


export default function PersonIkon() {

    return (
        <div className="ikon">
        <BsPerson/>
        </div>
    );
}; 