// Kodet af Michelle 

import React from "react";

export default function Title(props) {

    return (
        <div>
            <p>{props.name}</p>
        </div>
    );
}; 