// Kodet af Michelle 

import React from "react";


export default function Overskrift(props) {

    return (
        <div className="overskrift">
            <h2>{props.name}</h2>
        </div>
    );
}; 