// Kodet af Michelle 

import React from "react";

export default function Button() {

    const handleClick = () => {
    
    }

    return (
        <>
        <button className="Btn" onClick={handleClick}>Check</button>
        </>
       
    );
};  